import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, ContentChildren, ViewChild, NgModule } from '@angular/core';
import * as i2 from '@angular/router';
import { RouterModule } from '@angular/router';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import * as i3 from 'primeng/ripple';
import { RippleModule } from 'primeng/ripple';
import * as i4 from 'primeng/tooltip';
import { TooltipModule } from 'primeng/tooltip';
import { UniqueComponentId, ObjectUtils } from 'primeng/utils';
import { DomHandler } from 'primeng/dom';

/**
 * Dock is a navigation component consisting of menuitems.
 * @group Components
 */
const _c0 = ["list"];
const _c1 = a0 => ({
  "p-disabled": a0
});
const _c2 = () => ({
  exact: false
});
const _c3 = a0 => ({
  $implicit: a0
});
function Dock_li_4_a_2_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 12);
  }
  if (rf & 2) {
    const item_r3 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("ngClass", item_r3.icon)("ngStyle", item_r3.iconStyle);
  }
}
function Dock_li_4_a_2_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Dock_li_4_a_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 9);
    i0.ɵɵtemplate(1, Dock_li_4_a_2_span_1_Template, 1, 2, "span", 10)(2, Dock_li_4_a_2_ng_container_2_Template, 1, 0, "ng-container", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r3 = i0.ɵɵnextContext().$implicit;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("routerLink", item_r3.routerLink)("queryParams", item_r3.queryParams)("ngClass", i0.ɵɵpureFunction1(17, _c1, item_r3.disabled))("routerLinkActiveOptions", item_r3.routerLinkActiveOptions || i0.ɵɵpureFunction0(19, _c2))("target", item_r3.target)("tooltipOptions", item_r3.tooltipOptions)("fragment", item_r3.fragment)("queryParamsHandling", item_r3.queryParamsHandling)("preserveFragment", item_r3.preserveFragment)("skipLocationChange", item_r3.skipLocationChange)("replaceUrl", item_r3.replaceUrl)("state", item_r3.state);
    i0.ɵɵattribute("tabindex", item_r3.disabled || ctx_r3.readonly ? null : item_r3.tabindex ? item_r3.tabindex : "-1")("aria-hidden", true);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r3.icon && !ctx_r3.itemTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r3.itemTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(20, _c3, item_r3));
  }
}
function Dock_li_4_ng_template_3_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 12);
  }
  if (rf & 2) {
    const item_r3 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("ngClass", item_r3.icon)("ngStyle", item_r3.iconStyle);
  }
}
function Dock_li_4_ng_template_3_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Dock_li_4_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 13);
    i0.ɵɵtemplate(1, Dock_li_4_ng_template_3_span_1_Template, 1, 2, "span", 10)(2, Dock_li_4_ng_template_3_ng_container_2_Template, 1, 0, "ng-container", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext();
    const item_r3 = ctx_r5.$implicit;
    const i_r5 = ctx_r5.index;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("tooltipPosition", item_r3.tooltipPosition)("tooltipOptions", item_r3.tooltipOptions)("ngClass", i0.ɵɵpureFunction1(10, _c1, item_r3.disabled))("target", item_r3.target);
    i0.ɵɵattribute("href", item_r3.url || null, i0.ɵɵsanitizeUrl)("tabindex", item_r3.disabled || i_r5 !== ctx_r3.activeIndex && ctx_r3.readonly ? null : item_r3.tabindex ? item_r3.tabindex : "-1")("aria-hidden", true);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r3.icon && !ctx_r3.itemTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r3.itemTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(12, _c3, item_r3));
  }
}
function Dock_li_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 6);
    i0.ɵɵlistener("click", function Dock_li_4_Template_li_click_0_listener($event) {
      const item_r3 = i0.ɵɵrestoreView(_r2).$implicit;
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.onItemClick($event, item_r3));
    })("mouseenter", function Dock_li_4_Template_li_mouseenter_0_listener() {
      const i_r5 = i0.ɵɵrestoreView(_r2).index;
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.onItemMouseEnter(i_r5));
    });
    i0.ɵɵelementStart(1, "div", 7);
    i0.ɵɵtemplate(2, Dock_li_4_a_2_Template, 3, 22, "a", 8)(3, Dock_li_4_ng_template_3_Template, 3, 14, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const item_r3 = ctx.$implicit;
    const i_r5 = ctx.index;
    const elseBlock_r7 = i0.ɵɵreference(4);
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r3.itemClass(item_r3, i_r5));
    i0.ɵɵattribute("id", ctx_r3.getItemId(item_r3, i_r5))("aria-label", item_r3.label)("aria-disabled", ctx_r3.disabled(item_r3))("data-pc-section", "menuitem")("data-p-focused", ctx_r3.isItemActive(ctx_r3.getItemId(item_r3, i_r5)))("data-p-disabled", ctx_r3.disabled(item_r3) || false);
    i0.ɵɵadvance();
    i0.ɵɵattribute("data-pc-section", "content");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r3.isClickableRouterLink(item_r3))("ngIfElse", elseBlock_r7);
  }
}
class Dock {
  el;
  cd;
  /**
   * Current id state as a string.
   * @group Props
   */
  id;
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * MenuModel instance to define the action items.
   * @group Props
   */
  model = null;
  /**
   * Position of element.
   * @group Props
   */
  position = 'bottom';
  /**
   * Defines a string that labels the input for accessibility.
   * @group Props
   */
  ariaLabel;
  /**
   * Defines a string that labels the dropdown button for accessibility.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * Callback to execute when button is focused.
   * @param {FocusEvent} event - Focus event.
   * @group Emits
   */
  onFocus = new EventEmitter();
  /**
   * Callback to invoke when the component loses focus.
   * @param {FocusEvent} event - Focus event.
   * @group Emits
   */
  onBlur = new EventEmitter();
  templates;
  listViewChild;
  itemTemplate;
  currentIndex;
  tabindex = 0;
  focused = false;
  focusedOptionIndex = -1;
  get focusedOptionId() {
    return this.focusedOptionIndex !== -1 ? this.focusedOptionIndex : null;
  }
  constructor(el, cd) {
    this.el = el;
    this.cd = cd;
    this.currentIndex = -3;
  }
  ngOnInit() {
    this.id = this.id || UniqueComponentId();
  }
  ngAfterContentInit() {
    this.templates?.forEach(item => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;
        default:
          this.itemTemplate = item.template;
          break;
      }
    });
  }
  getItemId(item, index) {
    return item && item?.id ? item.id : `${index}`;
  }
  getItemProp(processedItem, name) {
    return processedItem && processedItem.item ? ObjectUtils.getItemValue(processedItem.item[name]) : undefined;
  }
  disabled(item) {
    return typeof item.disabled === 'function' ? item.disabled() : item.disabled;
  }
  isItemActive(id) {
    return id === this.focusedOptionIndex;
  }
  onListMouseLeave() {
    this.currentIndex = -3;
    this.cd.markForCheck();
  }
  onItemMouseEnter(index) {
    this.currentIndex = index;
    if (index === 1) {}
    this.cd.markForCheck();
  }
  onItemClick(e, item) {
    if (item.command) {
      item.command({
        originalEvent: e,
        item
      });
    }
  }
  onListFocus(event) {
    this.focused = true;
    this.changeFocusedOptionIndex(0);
    this.onFocus.emit(event);
  }
  onListBlur(event) {
    this.focused = false;
    this.focusedOptionIndex = -1;
    this.onBlur.emit(event);
  }
  onListKeyDown(event) {
    switch (event.code) {
      case 'ArrowDown':
        {
          if (this.position === 'left' || this.position === 'right') this.onArrowDownKey();
          event.preventDefault();
          break;
        }
      case 'ArrowUp':
        {
          if (this.position === 'left' || this.position === 'right') this.onArrowUpKey();
          event.preventDefault();
          break;
        }
      case 'ArrowRight':
        {
          if (this.position === 'top' || this.position === 'bottom') this.onArrowDownKey();
          event.preventDefault();
          break;
        }
      case 'ArrowLeft':
        {
          if (this.position === 'top' || this.position === 'bottom') this.onArrowUpKey();
          event.preventDefault();
          break;
        }
      case 'Home':
        {
          this.onHomeKey();
          event.preventDefault();
          break;
        }
      case 'End':
        {
          this.onEndKey();
          event.preventDefault();
          break;
        }
      case 'Enter':
      case 'Space':
        {
          this.onSpaceKey();
          event.preventDefault();
          break;
        }
      default:
        break;
    }
  }
  onArrowDownKey() {
    const optionIndex = this.findNextOptionIndex(this.focusedOptionIndex);
    this.changeFocusedOptionIndex(optionIndex);
  }
  onArrowUpKey() {
    const optionIndex = this.findPrevOptionIndex(this.focusedOptionIndex);
    this.changeFocusedOptionIndex(optionIndex);
  }
  onHomeKey() {
    this.changeFocusedOptionIndex(0);
  }
  onEndKey() {
    this.changeFocusedOptionIndex(DomHandler.find(this.listViewChild.nativeElement, 'li[data-pc-section="menuitem"][data-p-disabled="false"]').length - 1);
  }
  onSpaceKey() {
    const element = DomHandler.findSingle(this.listViewChild.nativeElement, `li[id="${`${this.focusedOptionIndex}`}"]`);
    const anchorElement = element && DomHandler.findSingle(element, '[data-pc-section="action"]');
    anchorElement ? anchorElement.click() : element && element.click();
  }
  findNextOptionIndex(index) {
    const menuitems = DomHandler.find(this.listViewChild.nativeElement, 'li[data-pc-section="menuitem"][data-p-disabled="false"]');
    const matchedOptionIndex = [...menuitems].findIndex(link => link.id === index);
    return matchedOptionIndex > -1 ? matchedOptionIndex + 1 : 0;
  }
  changeFocusedOptionIndex(index) {
    const menuitems = DomHandler.find(this.listViewChild.nativeElement, 'li[data-pc-section="menuitem"][data-p-disabled="false"]');
    let order = index >= menuitems.length ? menuitems.length - 1 : index < 0 ? 0 : index;
    this.focusedOptionIndex = menuitems[order].getAttribute('id');
  }
  findPrevOptionIndex(index) {
    const menuitems = DomHandler.find(this.listViewChild.nativeElement, 'li[data-pc-section="menuitem"][data-p-disabled="false"]');
    const matchedOptionIndex = [...menuitems].findIndex(link => link.id === index);
    return matchedOptionIndex > -1 ? matchedOptionIndex - 1 : 0;
  }
  get containerClass() {
    return {
      ['p-dock p-component ' + ` p-dock-${this.position}`]: true
    };
  }
  isClickableRouterLink(item) {
    return item.routerLink && !item.disabled;
  }
  itemClass(item, index) {
    return {
      'p-dock-item': true,
      'p-dock-item-second-prev': this.currentIndex - 2 === index,
      'p-dock-item-prev': this.currentIndex - 1 === index,
      'p-dock-item-current': this.currentIndex === index,
      'p-dock-item-next': this.currentIndex + 1 === index,
      'p-dock-item-second-next': this.currentIndex + 2 === index,
      'p-focus': this.isItemActive(this.getItemId(item, index))
    };
  }
  static ɵfac = function Dock_Factory(t) {
    return new (t || Dock)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Dock,
    selectors: [["p-dock"]],
    contentQueries: function Dock_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function Dock_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.listViewChild = _t.first);
      }
    },
    hostAttrs: [1, "p-element"],
    inputs: {
      id: "id",
      style: "style",
      styleClass: "styleClass",
      model: "model",
      position: "position",
      ariaLabel: "ariaLabel",
      ariaLabelledBy: "ariaLabelledBy"
    },
    outputs: {
      onFocus: "onFocus",
      onBlur: "onBlur"
    },
    decls: 5,
    vars: 13,
    consts: [["list", ""], ["elseBlock", ""], [3, "ngClass", "ngStyle"], [1, "p-dock-list-container"], ["role", "menu", 1, "p-dock-list", 3, "focus", "blur", "keydown", "mouseleave", "tabindex"], ["role", "menuitem", 3, "ngClass", "click", "mouseenter", 4, "ngFor", "ngForOf"], ["role", "menuitem", 3, "click", "mouseenter", "ngClass"], [1, "p-menuitem-content"], ["pRipple", "", "class", "p-dock-link", "pTooltip", "", 3, "routerLink", "queryParams", "ngClass", "routerLinkActiveOptions", "target", "tooltipOptions", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state", 4, "ngIf", "ngIfElse"], ["pRipple", "", "pTooltip", "", 1, "p-dock-link", 3, "routerLink", "queryParams", "ngClass", "routerLinkActiveOptions", "target", "tooltipOptions", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state"], ["class", "p-dock-action-icon", 3, "ngClass", "ngStyle", 4, "ngIf"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [1, "p-dock-action-icon", 3, "ngClass", "ngStyle"], ["pRipple", "", "pTooltip", "", 1, "p-dock-link", 3, "tooltipPosition", "tooltipOptions", "ngClass", "target"]],
    template: function Dock_Template(rf, ctx) {
      if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "div", 2)(1, "div", 3)(2, "ul", 4, 0);
        i0.ɵɵlistener("focus", function Dock_Template_ul_focus_2_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onListFocus($event));
        })("blur", function Dock_Template_ul_blur_2_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onListBlur($event));
        })("keydown", function Dock_Template_ul_keydown_2_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onListKeyDown($event));
        })("mouseleave", function Dock_Template_ul_mouseleave_2_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onListMouseLeave());
        });
        i0.ɵɵtemplate(4, Dock_li_4_Template, 5, 10, "li", 5);
        i0.ɵɵelementEnd()()();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngClass", ctx.containerClass)("ngStyle", ctx.style);
        i0.ɵɵattribute("data-pc-name", "dock");
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("tabindex", ctx.tabindex);
        i0.ɵɵattribute("id", ctx.id)("aria-orientation", ctx.position === "bottom" || ctx.position === "top" ? "horizontal" : "vertical")("aria-activedescendant", ctx.focused ? ctx.focusedOptionId : undefined)("aria-label", ctx.ariaLabel)("aria-labelledby", ctx.ariaLabelledBy)("data-pc-section", "menu");
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", ctx.model);
      }
    },
    dependencies: [i1.NgClass, i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, i2.RouterLink, i3.Ripple, i4.Tooltip],
    styles: ["@layer primeng{.p-dock{position:absolute;z-index:1;display:flex;justify-content:center;align-items:center;pointer-events:none}.p-dock-list-container{display:flex;pointer-events:auto}.p-dock-list{margin:0;padding:0;list-style:none;display:flex;align-items:center;justify-content:center}.p-dock-item{transition:all .2s cubic-bezier(.4,0,.2,1);will-change:transform}.p-dock-link{display:flex;flex-direction:column;align-items:center;justify-content:center;position:relative;overflow:hidden;cursor:default}.p-dock-item-second-prev,.p-dock-item-second-next{transform:scale(1.2)}.p-dock-item-prev,.p-dock-item-next{transform:scale(1.4)}.p-dock-item-current{transform:scale(1.6);z-index:1}.p-dock-top{left:0;top:0;width:100%}.p-dock-top .p-dock-item{transform-origin:center top}.p-dock-bottom{left:0;bottom:0;width:100%}.p-dock-bottom .p-dock-item{transform-origin:center bottom}.p-dock-right{right:0;top:0;height:100%}.p-dock-right .p-dock-item{transform-origin:center right}.p-dock-right .p-dock-list{flex-direction:column}.p-dock-left{left:0;top:0;height:100%}.p-dock-left .p-dock-item{transform-origin:center left}.p-dock-left .p-dock-list{flex-direction:column}}\n"],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Dock, [{
    type: Component,
    args: [{
      selector: 'p-dock',
      template: `
        <div [ngClass]="containerClass" [ngStyle]="style" [class]="styleClass" [attr.data-pc-name]="'dock'">
            <div class="p-dock-list-container">
                <ul
                    #list
                    [attr.id]="id"
                    class="p-dock-list"
                    role="menu"
                    [attr.aria-orientation]="position === 'bottom' || position === 'top' ? 'horizontal' : 'vertical'"
                    [attr.aria-activedescendant]="focused ? focusedOptionId : undefined"
                    [tabindex]="tabindex"
                    [attr.aria-label]="ariaLabel"
                    [attr.aria-labelledby]="ariaLabelledBy"
                    [attr.data-pc-section]="'menu'"
                    (focus)="onListFocus($event)"
                    (blur)="onListBlur($event)"
                    (keydown)="onListKeyDown($event)"
                    (mouseleave)="onListMouseLeave()"
                >
                    <li
                        *ngFor="let item of model; let i = index"
                        [attr.id]="getItemId(item, i)"
                        [ngClass]="itemClass(item, i)"
                        role="menuitem"
                        [attr.aria-label]="item.label"
                        [attr.aria-disabled]="disabled(item)"
                        (click)="onItemClick($event, item)"
                        (mouseenter)="onItemMouseEnter(i)"
                        [attr.data-pc-section]="'menuitem'"
                        [attr.data-p-focused]="isItemActive(getItemId(item, i))"
                        [attr.data-p-disabled]="disabled(item) || false"
                    >
                        <div class="p-menuitem-content" [attr.data-pc-section]="'content'">
                            <a
                                *ngIf="isClickableRouterLink(item); else elseBlock"
                                pRipple
                                [routerLink]="item.routerLink"
                                [queryParams]="item.queryParams"
                                [ngClass]="{ 'p-disabled': item.disabled }"
                                class="p-dock-link"
                                [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
                                [target]="item.target"
                                [attr.tabindex]="item.disabled || readonly ? null : item.tabindex ? item.tabindex : '-1'"
                                pTooltip
                                [tooltipOptions]="item.tooltipOptions"
                                [fragment]="item.fragment"
                                [queryParamsHandling]="item.queryParamsHandling"
                                [preserveFragment]="item.preserveFragment"
                                [skipLocationChange]="item.skipLocationChange"
                                [replaceUrl]="item.replaceUrl"
                                [state]="item.state"
                                [attr.aria-hidden]="true"
                            >
                                <span class="p-dock-action-icon" *ngIf="item.icon && !itemTemplate" [ngClass]="item.icon" [ngStyle]="item.iconStyle"></span>
                                <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
                            </a>
                            <ng-template #elseBlock>
                                <a
                                    [tooltipPosition]="item.tooltipPosition"
                                    [attr.href]="item.url || null"
                                    class="p-dock-link"
                                    pRipple
                                    pTooltip
                                    [tooltipOptions]="item.tooltipOptions"
                                    [ngClass]="{ 'p-disabled': item.disabled }"
                                    [target]="item.target"
                                    [attr.tabindex]="item.disabled || (i !== activeIndex && readonly) ? null : item.tabindex ? item.tabindex : '-1'"
                                    [attr.aria-hidden]="true"
                                >
                                    <span class="p-dock-action-icon" *ngIf="item.icon && !itemTemplate" [ngClass]="item.icon" [ngStyle]="item.iconStyle"></span>
                                    <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
                                </a>
                            </ng-template>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: ["@layer primeng{.p-dock{position:absolute;z-index:1;display:flex;justify-content:center;align-items:center;pointer-events:none}.p-dock-list-container{display:flex;pointer-events:auto}.p-dock-list{margin:0;padding:0;list-style:none;display:flex;align-items:center;justify-content:center}.p-dock-item{transition:all .2s cubic-bezier(.4,0,.2,1);will-change:transform}.p-dock-link{display:flex;flex-direction:column;align-items:center;justify-content:center;position:relative;overflow:hidden;cursor:default}.p-dock-item-second-prev,.p-dock-item-second-next{transform:scale(1.2)}.p-dock-item-prev,.p-dock-item-next{transform:scale(1.4)}.p-dock-item-current{transform:scale(1.6);z-index:1}.p-dock-top{left:0;top:0;width:100%}.p-dock-top .p-dock-item{transform-origin:center top}.p-dock-bottom{left:0;bottom:0;width:100%}.p-dock-bottom .p-dock-item{transform-origin:center bottom}.p-dock-right{right:0;top:0;height:100%}.p-dock-right .p-dock-item{transform-origin:center right}.p-dock-right .p-dock-list{flex-direction:column}.p-dock-left{left:0;top:0;height:100%}.p-dock-left .p-dock-item{transform-origin:center left}.p-dock-left .p-dock-list{flex-direction:column}}\n"]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }], {
    id: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    model: [{
      type: Input
    }],
    position: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    onFocus: [{
      type: Output
    }],
    onBlur: [{
      type: Output
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    listViewChild: [{
      type: ViewChild,
      args: ['list', {
        static: false
      }]
    }]
  });
})();
class DockModule {
  static ɵfac = function DockModule_Factory(t) {
    return new (t || DockModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: DockModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, RouterModule, RippleModule, TooltipModule, SharedModule, TooltipModule, RouterModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DockModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RouterModule, RippleModule, TooltipModule],
      exports: [Dock, SharedModule, TooltipModule, RouterModule],
      declarations: [Dock]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Dock, DockModule };
